@import '../../index.scss';

.Modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  display: flex;
  flex-direction: column;

  &__backdrop {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: $color-noir-alpha;
    animation: fadeIn $animationSpeedDefault ease-in-out;
  }

  &__content {
    position: relative;
    width: 780px;
    height: 390px;
    background: $color-blanc;
    font-family: $font-family-gotham-book;
    vertical-align: middle;
    margin: auto;
    animation: slideIn $animationSpeedDefault ease-in-out;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
