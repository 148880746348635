@import "../../index.scss";

.Button {
  width: 100%;
  height: 40px;
  background-color: $color-noir;
  display: inline-flex;
  position: relative;
  align-items: center;
  justify-content: center;
  font-family: $font-family-roboto-medium;
  font-size: 16px;
  line-height: 1.5;

  &:hover {
    transition: background-color $animationSpeedDefault ease-in-out;
    background-color: $color-noir-alpha;
  }
}

.Button___secondary {
  background-color: $color-blanc;
  font-family: $font-family-roboto-regular;

  .Button__label {
    color: $color-noir-alpha;
    padding-right: 16px;
  }

  &:hover {
    background-color: $color-blanc;

    .Button__label {
      transition: color $animationSpeedDefault ease-in-out;
      color: $color-noir;
      padding-right: 16px;
    }

    .Button__icon {
      transition: fill $animationSpeedDefault ease-in-out;
      fill: $color-noir;
      margin-bottom: 1px;
    }
  }
}

.Button___disabled {
  pointer-events: none;
  text-decoration: none;
  background-color: rgba($color-noir, 0.5);
  cursor: not-allowed;

  &:hover {
    background-color: $color-grey;
  }
}

.Button__label {
  display: block;
  font-family: $font-family-roboto-regular;
  font-size: 16px;
  line-height: 1.5;
  text-align: center;
  color: $color-blanc;
}

.Button__icon {
  fill: $color-noir-alpha;
  margin-bottom: 1px;
}
