@import "../sass/variables";
@import "../sass/mixins";

.wrapper {
  z-index: 2;
}

.mobileNavToggle {
  display: flex;
  width: 20px;
  height: 24px;
  transform: rotate(0deg);
  transition: 0.5s ease-in-out;
  cursor: pointer;
  padding: $space * 5;
  align-items: center;
  z-index: 1;
  box-sizing: content-box;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
}

.burgerMenu {
  display: block;
  position: relative;
  height: 15px;
  width: 100%;

  @include mediumScreen {
    display: none;
  }
}

.burgerMenuLine {
  display: block;
  position: absolute;
  height: 2px;
  width: 100%;
  border-radius: 4px;
  opacity: 1;
  left: 0;
  transform: rotate(0deg);
  transition: transform 0.25s ease-in-out;
  background-color: $color-noir;

  &:nth-child(1) {
    top: 0px;
  }

  &:nth-child(2),
  &:nth-child(3) {
    top: 6px;
  }

  &:nth-child(4) {
    top: 12px;
  }
}

.isNaviOpen .mobileNavToggle .burgerMenuLine {
  &:nth-child(1) {
    top: 8px;
    width: 0%;
    left: 50%;
  }

  &:nth-child(2) {
    transform: rotate(45deg);
  }

  &:nth-child(3) {
    transform: rotate(-45deg);
  }

  &:nth-child(4) {
    top: 8px;
    width: 0%;
    left: 50%;
  }
}
