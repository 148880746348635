@import "../../index.scss";

.ProductCategoryTeaser {
  margin-bottom: 12 * $space;

  @include mediumScreen {
    margin-bottom: 30 * $space;
  }
}

.ProductCategoryTeaser__columns {
  flex-direction: column-reverse;
  display: flex;

  @include extraSmallScreen {
    flex-direction: row;
  }
}

.ProductCategoryTeaser__link {
  display: none;

  @include extraSmallScreen {
    display: block;
  }

  &.is-mobile {
    display: block;

    @include extraSmallScreen {
      display: none;
    }
  }
}

.ProductCategoryTeaser__columns___reversed {
  flex-direction: column-reverse;
  justify-content: flex-end;

  .ProductCategoryTeaser__headlines {
    align-items: flex-end;

    h1,
    h2 {
      text-align: right;
    }
  }
  .ProductCategoryTeaser__link {
    text-align: right;
  }

  @include extraSmallScreen {
    flex-direction: row-reverse;
  }
}

.ProductCategoryTeaser__mixedColumn {
  // display: flex;
  // flex-direction: column;

  @include extraSmallScreen {
    display: flex;
    flex-direction: column;
  }

  img {
    padding-bottom: 2 * $space;
  }
}

.ProductCategoryTeaser__headlines {
  display: flex;
  flex-direction: column;
  flex: 1 1 100%;

  &__subheadline {
    text-align: left;
    font-family: $font-family-roboto-medium;
    font-weight: 500;
    color: $color-noir;
    margin-bottom: 8px;
    font-size: 14px;
    line-height: 20px;

    @include smallScreen {
      font-size: 24px;
      line-height: 32px;
    }
  }
}

.ProductCategoryTeaser__headline {
  @include h5;
  @include wordbreak;

  // IE11
  box-sizing: border-box;
  max-width: 100%;

  @include smallScreen {
    @include h3;
  }
}

.ProductCategoryTeaser__optionalColumn {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.productsList {
  @include fluentScroll;

  img {
    width: 100%;
  }

  @include extraSmallScreen {
    flex-direction: row;
    overflow: auto;
  }
}

.ProductCategoryTeaser__ieImageFix {
  flex-shrink: 0;
}
