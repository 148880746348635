@import '../../index.scss';

.Icon {
    color: black;
    vertical-align: middle;
}

.Icon_xxSmall {
    height: 12px;
    width: 12px;
}

.Icon_xSmall {
    height: 16px;
    width: 16px;
}

.Icon_smaller {
    height: 20px;
    width: 20px;
}

.Icon_small {
    height: 24px;
    width: 24px;
}

.Icon_medium {
    height: 40px;
    width: 40px;
}

// Pictograms

.Icon_large {
    height: 60px;
    width: 60px;
}

.Icon_xLarge {
    height: 80px;
    width: 80px;
}

.Icon_xxLarge {
    height: 120px;
    width: 120px;
}

