@import '../sass/variables';

.wrapper {
  margin-top: 10*$space;
  position: relative;
}

.list {
  margin-top: 5*$space;
}

.logo {
  position: relative;
}
