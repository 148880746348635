@import '../../index.scss';

.InputText {
    cursor: pointer;
    position: relative;
}

.InputText__input {
    width: 100%;
    height: 40px;
    border: 1px solid $color-grey;
    font-family: $font-family-roboto-regular;
    font-size: 16px;
    padding: $doubleSpace 2*$doubleSpace;

    &:focus {
        outline: none;
    }
}

.InputText__input___marginbottom {
    margin-bottom: 10*$space;
}

.InputText__label {
    font-family: $font-family-roboto-regular;
    font-size: 12px;
    line-height: 1.33;
    color: $color-noir;
    margin-bottom: $space;
}

.InputText__icon_wrapper {
    position: absolute;
    right: 3*$space;
    top: 1.5*$space;
}
.InputText__icon_wrapper__withLabel {
    top: 7*$space;
}

.InputText__icon:hover {
    fill: $color-noir-alpha;
    transition: fill $animationSpeedDefault ease-in-out;
}