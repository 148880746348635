@import "../sass/variables";
@import "../sass/mixins";
@import "../sass/typography";

.navItem {
  margin-bottom: 8 * $space;

  @include mediumScreen {
    margin-bottom: 0;
    margin-right: 3%;
  }

  @include mediumScreen {
    margin-right: 2%;
  }

  @include largeScreen {
    margin-right: 3%;
  }

  @include extraLargeScreen {
    margin-right: 3%;
  }
}

.navItemLinkWrapper {
  display: flex;
  justify-content: space-between;

  @include mediumScreen {
    display: block;
  }
}

.navItemLinkWrapper:hover {
  border-bottom: 1px solid $color-noir;
}

.navItemLink {
  position: relative;
  font-size: 16px;
  font-family: $font-family-gotham-book;
  line-height: 1.2;
  text-transform: uppercase;
  color: #000000;
  display: inline;
  z-index: 10;
  white-space: wrap;

  @include smallScreen {
    white-space: nowrap;
  }

  & > div {
    display: none;
  }

  @include mediumScreen {
    &:hover {
      & > div {
        display: block;
      }
    }
  }

  // strong {
  //   border-bottom: 1px solid $color-noir;
  // }

  @include extraSmallScreen {
    font-size: 16px;
  }
  @include smallScreen {
    font-size: 14px;
  }

  @include mediumScreen {
    font-size: 16px;
  }

  &:visited,
  &:visited,
  &:active {
    color: #000000;
  }
}

.navItemLinkActive {
  min-width: 80%;

  p {
    font-weight: bold;
  }
}

.navItemToggler {
  display: block;
  transform: rotateZ(90deg);
  display: inline-block;
  z-index: 2;
  transition: transform $animationSpeedDefault ease-in-out;

  @include mediumScreen {
    display: none !important;
  }
}

.submenu {
  top: $mainNavigationHeight;
  position: absolute;
  width: 100%;
}

.dropdown {
  position: relative;
  opacity: 0;
  display: none;
  max-height: 0;
  transition: all $animationSpeedDefault ease-in-out;
  // overflow: hidden;

  @include smallScreen {
    position: absolute;
  }

  .navItemLink {
    text-transform: none;

    p {
      font-weight: normal;
      text-transform: lowercase;
    }

    p::first-letter {
      text-transform: uppercase;
    }
  }

  .productCategory {
    margin-top: 2 * $doubleSpace;
    margin-bottom: 2 * $doubleSpace;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &:before {
    content: "";
    position: absolute;
    top: 37px;
    left: -100vw;
    background-color: #fff;
    width: 200vw;
    height: 70%;
    opacity: 0.9;
    transition: height 1s linear;
  }

  &:before:hover {
    height: 100%;
    transition: max-height 1s linear;
  }
}

.isActive {
  .dropdown {
    position: relative;
    opacity: 1;
    display: block;
    max-height: 100vh;

    @include mediumScreen {
      position: absolute;
    }
  }

  .navItemToggler {
    transform: rotateZ(270deg);
  }
}

.catergoryList {
  width: auto;
  padding: 4 * $space 0 4 * $space 4 * $space;

  & > a {
    font-size: 16px !important;
  }

  @include mediumScreen {
    width: 100vw;
    padding: 10 * $space 0 4 * $space 0;
  }

  & li {
    margin-right: 1%;
    min-width: 150px;
  }

  & li:first-of-type {
    min-width: 130px;
  }

  & li:last-of-type {
    margin-right: 0;
  }
}

ul li {
  position: relative;
  display: block;

  @include mediumScreen {
    display: inline-flex;
  }
}

.productCategory {
  position: relative;
}

.languageSwitchItem {
  margin-top: 4 * $space;
  margin-bottom: 4 * $space;

  &:first-of-type {
    margin-top: 0 * $space;
  }
}

.current {
  font-weight: bold;
}
