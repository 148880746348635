@import '../../sass/variables';

.wrapper {
  max-width: 342px;
}
.image {
  width: 100%;
  padding-bottom: 8px;
}
.copy {
  text-align: left;
  margin-top: 5*$space;
}