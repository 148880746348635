@import '../../sass/variables';
@import '../../sass/typography';
@import '../../sass/mixins';


.TopBar {
  display: none;

  @include smallScreen {
    background: $color-blanc;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    height: $topbarHeight;
    align-items: center;
  }
}
