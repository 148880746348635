@import "../src/index.scss";
@import "../src/sass/grid.scss";

.App {
  width: 100%;
  position: relative;
  max-width: $medium-screen-max-width;
  margin: 0 auto;
}

// Fun with IE11
p {
  width: 100%;
}

.head {
  height: $headHeightMobile;
  position: fixed;
  z-index: 5;
  width: 100%;
  max-width: $medium-screen-max-width;
  top: 0;
  background: $color-blanc;
  padding: 0 $outerPadding;

  @include smallScreen {
    height: $headHeight;
    padding: 0 $outerPaddingDesktop;
  }

  @include extraLargeScreen {
    max-width: calc(#{$large-screen-max-width} - #{$outerPaddingDesktop});
  }
}

.main {
  position: relative;
  margin-top: calc(#{$headHeightMobile} - 40px);
  padding: 7 * $doubleSpace $outerPadding 60px $outerPadding;

  @include smallScreen {
    margin-top: #{$headHeightMobile};
  }
  @include mediumScreen {
    margin-top: calc(#{$headHeight} + 120px);
    padding: 0 $outerPaddingDesktop 120px $outerPaddingDesktop;
  }
}

.footerX {
  padding: 0.75rem;
  padding-top: 24px;
  background-color: #fafafa;

  > div {
    max-width: $medium-screen-max-width;
    margin: 0 auto;
  }
}

@font-face {
  font-family: "GothamBook";
  src: url("fonts/Gotham-Book.woff") format("woff");
}

@font-face {
  font-family: "GothamLight";
  src: url("fonts/Gotham-Light.woff") format("woff");
}

@font-face {
  font-family: "RobotoMedium";
  src: url("fonts/Roboto-Medium.woff") format("woff"),
    url("fonts/Roboto-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "RobotoRegular";
  src: url("fonts/Roboto-Regular.woff") format("woff"),
    url("fonts/Roboto-Regular.ttf") format("truetype");
}
