@import "../../index.scss";

.TextTeaser {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 952px;
  margin: 0 auto;
  text-align: left;
  padding: 0 0.75em;

  @include mediumScreen {
    padding: 0;
  }
}

.TextTeaser___small {
  max-width: 472px;
}

.TextTeaser__headline {
  font-family: $font-family-roboto-medium;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  color: $color-noir;
  margin-bottom: 8px;
}

.TextTeaser__copy {
  width: 100%;
  font-family: $font-family-roboto-regular;
  font-size: 16px;
  line-height: 1.5;
  color: $color-noir;
}
