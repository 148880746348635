@import '../../index.scss';

.ProductNavigation {
  display: inline-flex;
  flex-direction: column;
  padding: 56px;
  z-index: 1;
  box-sizing: content-box;
  position: absolute;
  right: 0;
  top: 24px; // 80px from design - 56 paddingTop
  width: 385px;
}

.ProductNavigation___mobile {
  flex-direction: row;
  padding: 0;
  flex-wrap: nowrap;
  width: 100%;
  @include fluentScroll();
}
