@import '../../index.scss';

.FooterLink {
    display: flex;
    font-family: $font-family-roboto-regular;
    font-size: 16px;
    line-height: 2;
    color: $color-noir;
    padding: $space 0;

    
    &:hover {
        transition: color .2s ease-in-out;
        color: $color-noir-alpha;
        
        .FooterLink__icon {
            fill: $color-noir-alpha;
        }
    }
}

.FooterLink__static {
  display: flex;
  font-family: $font-family-roboto-regular;
  font-size: 16px;
  line-height: 2;
  color: $color-noir-alpha;
  padding: $space 0;
  width: initial;
}

.FooterLink__icon {
    fill: $color-noir;
    transition: fill .2s ease-in-out;
    margin-right: 3*$space;
}
