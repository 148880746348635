@import '../src/sass/typography.scss';
@import '../src/sass/variables.scss';
@import '../src/sass/animations.scss';
@import '../src/sass/mixins.scss';

html, body {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

ul, li {
  margin: 0;
  padding: 0;
}

blockquote {
  font-family: $font-family-gotham-book;
  font-size: 20px;
  line-height: 1.6;
  color: $color-noir;
  margin: 8px 0 32px 0;
  text-transform: uppercase;
}

:focus {outline:0 !important;}
