@import '../../index.scss';

.Footer {
    margin-top: 43*$space;
    text-align: left;
}

.Footer__column {
    flex-direction: column;
    align-items: center;
    margin-bottom: 5*$doubleSpace;

    &:last-child {
        margin-bottom: 0;
    }

}

@include smallScreen {
    .Footer {
        padding: 5*$doubleSpace 0 16*$space 0; 
    }
    .Footer__column {
        margin-bottom: 0;
    }
}

.Footer__headline {
    font-family: $font-family-roboto-medium;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5;
    color: $color-noir;
    margin-bottom: 4*$space;
}