@import '../../sass/variables';
@import '../../sass/mixins';

%image-gallery-custom-nav {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 4;
  padding: 10px;
  background: transparent;
  border: 0;
  cursor: pointer;

  &::before {
    content: '';
    background-repeat: no-repeat;
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: scale(1.5);
    border: 0;
  }
}

$container-size : 100%;
$image-height: 600;
$image-width: 464;

.image-gallery-image {
  width       : $container-size;
  height      : 0;
  padding-bottom: 80%;
  line-height : $container-size;
  position: relative;

  @include extraSmallScreen {
    padding-bottom: $image-height/$image-width * 100%;
  }

  img {
    position: absolute;
    display        : inline-block;
    max-width      : 100%;
    max-height     : 100%;
    width          : auto;
    height         : auto;
    vertical-align : middle;
    left: 50%;
    transform: translate(-50%);
  }
}


.gallery-wrapper {
  text-align: center;

  .image-gallery-slide {
    padding: 0 1px;
  }

  .image-gallery-custom-left-nav {
    @extend %image-gallery-custom-nav;
    left: 0;

    &::before {
      background-image:url(../../icons/baseline-chevron_left-24px.svg);
    }
  }

  .image-gallery-custom-right-nav {
    @extend %image-gallery-custom-nav;
    right: 0;

    &::before {
      background-image:url(../../icons/baseline-chevron_right-24px.svg);
    }
  }

  .image-gallery-thumbnails {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .image-gallery-thumbnail {
    &.active {
      border: 4px solid $color-noir;
    }
  }
}
