@import '../../index.scss';

.SearchResultItem {
    display: flex;
    flex-direction: column;
    text-align: left;
    margin-bottom: 3 * $doubleSpace;
}

.SearchResultItem__headline {
    font-family: $font-family-roboto-medium;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5;
    color: $color-noir;
    margin-bottom: $doubleSpace;
}

.SearchResultItem__text {
    font-family: $font-family-roboto-regular;
    font-size: 16px;
    line-height: 1.5;
    color: $color-noir;
    margin-bottom: $doubleSpace;
}
