@import '../../sass/variables';

.checkbox {
  margin-right: 3*$doubleSpace;

  &:last-of-type {
    margin-right: 0;
  }
}

.checkbox input[type="radio"] {
  opacity: 0;
}

.label__text {
  margin-bottom: 8px;
  margin-top: 4px;
  text-align: center;
}

.checkbox input[type=radio] + label {
  border: 1px solid $color-blanc;
  padding: 0;
  height: 152px;
  width: 98px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom center;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
  color: $color-noir;

  &:hover {
    cursor: pointer;
  }

  img {
    display: block;
    height: 104px;
  }
}

.checkbox input[type=radio]:checked {
  + .label {
    border: 1px solid $color-noir;

    .label__text {
      // font-weight: bold;
    }
  }
}
