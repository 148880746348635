@import "../sass/variables";
@import "../sass/mixins";
@import "../sass/typography";

.navigation {
  position: absolute;
  z-index: 1;
  top: $mainNavigationHeightMobile;
  transition: opacity 0.25s ease-in-out;
  position: fixed;
  width: 100%;
  height: 100%;
  opacity: 0;
  padding: $space * 8;
  background: $color-blanc;
  left: 100%;
  margin-top: -2px;

  &.isNaviOpen {
    opacity: 1;
    left: 0;
    padding: $space * 4;
  }

  @include smallScreen {
    top: 120px;
  }

  @include mediumScreen {
    top: $mainNavigationHeight;
    position: static;
    max-height: $mainNavigationHeight;
    display: block !important;
    opacity: 1;
    padding: 0;
  }
}

@keyframes slideFadeIn {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }

  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}

.isNaviOpen .navItemsList {
  animation: slideFadeIn $animationSpeedSlow ease-in-out;
  animation-fill-mode: forwards;
}

.navItemsList {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  height: 100%;
  transform: translateY(100%);

  @include mediumScreen {
    transform: translateY(0);
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
  }

  &.isOpen {
    max-height: auto;
  }
}

nav {
  position: relative;
  width: 100%;
}

.navItem {
  z-index: 10;
  margin-bottom: 10 * $space;
  white-space: nowrap;

  @include mediumScreen {
    margin-bottom: 0;
    margin-right: 2%;
    &:last-of-type {
      margin-right: 0;
    }
  }

  @include largeScreen {
    margin-right: 3%;
  }
}

.navItem:last-of-type {
  margin-right: 0%;
}

.hideOnDesktop {
  display: block;

  @include mediumScreen {
    display: none;
  }
}

.navItemLink {
  font-size: 16px;
  font-family: $font-family-gotham-book;
  line-height: 1.2;
  text-transform: uppercase;
  color: #000000;
  display: inline;
  border-bottom: 1px solid transparent;

  @include extraSmallScreen {
    font-size: 16px;
  }

  @include smallScreen {
    font-size: 14px;
  }

  @include mediumScreen {
    font-size: 16px;
  }

  &:visited,
  &:active {
    color: #000000;
  }
}

.activeLocale {
  font-weight: 700;
}

.navItemLinkActive,
.navItemLink:hover {
  border-bottom: 1px solid $color-noir;
}

ul > li:hover {
  z-index: 1;

  .dropdown {
    opacity: 1;
    visibility: visible;
  }
}

ul li {
  position: relative;
  display: block;
}
