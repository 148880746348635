@import "../../index.scss";

.RichTextTeaser {
  font-family: $font-family-roboto-regular;
  color: $color-noir;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 952px;
  margin: 0 auto 24px auto;
  text-align: left;
  padding: 0 0.75em;
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    width: 100%;
    blockquote {
      width: 100%;
    }
  }

  @include mediumScreen {
    padding: 0;
  }

  blockquote {
    line-height: 1.4;
  }

  p {
    width: 100%;
  }

  .RichTextTeaser {
    padding: 0;
  }
}

.RichTextTeaser___small {
  max-width: 472px;
}

.RichTextTeaser__headline {
  font-family: $font-family-roboto-medium;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  color: $color-noir;
  margin-bottom: 2 * $space;
}

.RichTextTeaser__copy {
  font-family: $font-family-roboto-regular;
  font-size: 16px;
  line-height: 1.5;
  color: $color-noir;
  margin-bottom: 8 * $space;
}
