@import '../../sass/variables';
@import '../../sass/typography';

.wrapper {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border:none;
  border-radius: 0;
  font-size: 1em;
  width:100%;
  border: 1px solid #bbb;
  padding:.75em 1em .5em 1em;
  background-color:white;
  background-image:url(../../icons/baseline-expand_down-24px.svg);
  background-repeat: no-repeat;
  background-position: right 2*$doubleSpace center;
  height: 56px;
  border: 1px solid $color-grey;
  outline: none;
}

/* hide browser-styling (arrow) in IE10 */
select::-ms-expand {
  display:none;
}
