@import "../../sass/variables";
@import "../../sass/typography";
@import "../../sass/mixins";

$topPadding: 144px;

.wrapper {
  position: relative;
  margin-top: 15 * $space;
  padding: 0 0.75em;

  @include mediumScreen {
    margin-top: 25 * $space;
  }
}

.headline {
  font-family: $font-family-gotham-book;
  text-align: center;
  padding-bottom: 0;

  @include smallScreen {
    text-align: left;
    padding-bottom: 40px;
  }
}
