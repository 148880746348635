@import "../../../index.scss";

.ContentItems {
  display: inline-flex;
  flex-direction: column;
  cursor: pointer;
  align-items: flex-end;

  // I SHOULD NOT HAVE DONE THIS! Hagrid. (linebreak "Essentials by Ipuro")
  &:last-child {
    max-width: 250px;
    align-self: flex-end;
  }
}

.ContentItems__primary {
  display: inline-block;
  font-family: $font-family-gotham-book;
  font-size: 32px;
  line-height: 1.25;
  text-align: right;
  color: $color-noir;
  text-transform: uppercase;
  user-select: none;
  border-bottom: 2px solid transparent;
  margin-bottom: 6px;

  &:hover {
    transition: color $animationSpeedDefault ease-in-out;
    color: $color-noir-alpha;
  }
}

.ContentItems__primary___active {
  border-bottom: 2px solid $color-noir;

  &:hover {
    transition: border-color $animationSpeedDefault ease-in-out;
    border-color: $color-noir-alpha;
  }
}

.ContentItems__primary___mobile {
  font-size: 16px;
  margin-right: 24px;
  white-space: nowrap;
}

.ContentItems__secondary {
  font-family: $font-family-gotham-book;
  font-size: 18px;
  line-height: 1.6;
  letter-spacing: 1px;
  text-align: right;
  color: $color-noir;
  height: 9 * $space;
  user-select: none;
  display: block;

  &:hover {
    transition: color $animationSpeedDefault ease-in-out;
    color: $color-noir-alpha;
  }

  &:last-child {
    margin-bottom: 2 * $doubleSpace;
  }
}

.ContentItems__secondary___active {
  font-family: $font-family-gotham-book;

  &:hover {
    transition: border-color $animationSpeedDefault ease-in-out;
    border-color: $color-noir-alpha;
  }
}

.ContentItems__primary___white,
.ContentItems__secondary___white {
  color: $color-blanc;

  &:hover {
    color: $color-cuir-noble;
  }

  &.ContentItems__primary___active {
    border-color: $color-blanc;
  }

  &.ContentItems__secondary___active:hover {
    border-color: $color-cuir-noble;
  }
}

.ContentItems__expandable {
  visibility: hidden;
  max-height: 0;
  transition: all $animationSpeedSlow ease-in-out;
}

.ContentItems__expandable___expanded {
  visibility: visible;
  max-height: 500px;
}

.current {
  font-weight: bold;
}
