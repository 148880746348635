@import '../../index.scss';

.LegalLinkList {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 952px;
    margin: 0 auto 50px auto;
    text-align: left;
}

.LegalLinkList__headline {
    font-family: $font-family-roboto-medium;
    font-size: 24px;
    font-weight: 500;
    line-height: 1.33;
    color: $color-noir;
    margin-bottom: 24px;
}

.LegalLinkList__link {
    margin-bottom: 16px;
}