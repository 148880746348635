@import '../../index.scss';

.TopBarSearch {
    cursor: pointer;
}

.TopBarSearch__input {
    width: 0;
    transition: width $animationSpeedSlow;
    border-bottom: 2px solid $color-noir;

    input {
      border: none;
      font-family: $font-family-roboto-regular;
      font-size: 12px;
      padding-bottom: $doubleSpace;

      &:focus {
          outline: none;
      }
    }

}

.TopBarSearch__input___expanded {
    width: 250px;
    transition: width $animationSpeedSlow;
    color: $color-noir;
}
