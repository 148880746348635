@import "../../sass/variables";
@import "../../sass/typography.scss";
@import "../../sass/mixins";

.HeroTeaser {
  // display: flex;
  flex-direction: column;
  justify-content: flex-end;
  // height: 704px;
  background-position: center;
  // margin-bottom: 48px;
  width: calc(100% + 2 * #{$outerPadding});
  margin-left: -$outerPadding;
  position: relative;
  margin-bottom: 0px;

  @include smallScreen {
    width: 100%;
    margin-left: 0;
    margin-bottom: 36px;
  }
}

.HeroTeaser___onLandingPage {
  padding-top: 10px;
  margin-bottom: 38px;
  .HeroTeaser__imageWrapper {
    @include mediumScreen {
      padding-top: 48.88%;
    }
  }
}

.HeroTeaser__imageWrapper {
  position: relative;
  padding-top: 64.26%;
  display: none;
  @include extraSmallScreen {
    display: block;
    padding-top: 48.03%;
  }
  @include smallScreen {
    padding-top: 44.03%;
  }
  @include mediumScreen {
    padding-top: 41.03%;
  }

  .image {
    position: absolute;
    top: 0;
  }
}

.HeroTeaser__imageWrapperMobile {
  position: relative;
  padding-top: 64.26%;
  margin-bottom: 1rem;
  @include extraSmallScreen {
    display: none;
  }
  .imageMobile {
    position: absolute;
    top: 0;
  }
}

.image {
  display: none;
  @include extraSmallScreen {
    display: inline;
  }
}

.imageMobile {
  display: inline;
  position: absolute;
  top: 0;
  @include extraSmallScreen {
    display: none;
  }
}

.HeroTeaser__videoWrapperMobile {
  max-height: 520px;
  display: inline;
  @include extraSmallScreen {
    display: none;
  }
  // video {
  //   max-height: 520px;
  // }
}

.HeroTeaser__videoWrapper {
  max-height: 520px;
  display: inline;
  // video {
  //   max-height: 520px;
  // }
}

.HeroTeaser__headlineWrapper {
  margin: 2 * $doubleSpace 12px;

  @include smallScreen {
    position: absolute;
    bottom: 50%;
    right: 0;
    width: 100%;
    margin: 0;
  }
}

.HeroTeaser__headline {
  font-family: $font-family-gotham-book;
  font-size: 40px;
  line-height: 1.2;
  color: $color-noir;
  text-transform: uppercase;

  @include smallScreen {
    @include wordbreak;
    text-align: center;
    color: $color-blanc;
    margin-bottom: 88px;
  }
}
