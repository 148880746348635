@import "../../index.scss";

.CompositionTeaser {
  margin-top: 100px;
  margin-bottom: 12 * $space;
}

.CompositionTeaser__columns___reversed {
  flex-direction: row-reverse;
  justify-content: flex-end;

  .CompositionTeaser__headlines__subheadline,
  .CompositionTeaser__headlines__mainheadline {
    text-align: right;
  }
}

.CompositionTeaser__mixedColumn {
  display: flex;
  flex-direction: column;
}

.CompositionTeaser__headlines {
  display: flex;
  flex-direction: column;
  flex: 1;

  &__subheadline {
    text-align: left;
    font-family: $font-family-roboto-medium;
    font-weight: 500;
    color: $color-noir;
    margin-bottom: 8px;
    font-size: 14px;
    line-height: 20px;

    @include smallScreen {
      font-size: 24px;
      line-height: 1.33;
    }
  }

  &__mainheadline {
    text-align: left;
  }
}

.CompositionTeaser__optionalColumn {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  div {
    margin-bottom: 0;
  }
}

.CompositionTeaser__centerImage {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
}
