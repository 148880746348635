@import '../../src/index.scss';
@import '../sass/typography.scss';


.ProductSearch__headline {
  margin-bottom: 5*$doubleSpace;
}

.ProductSearch__results_conclusion {
  font-family: $font-family-roboto-regular;
  font-size: 12px;
  line-height: 1.33;
  color: $color-noir;
  margin-top: $doubleSpace;
  text-align: left;
  margin-bottom: 7*$doubleSpace;
}

.ProductSearch__results_headline {
  font-family: $font-family-gotham-book;
  font-size: 28px;
  line-height: 1.43;
  color: $color-noir;
  margin-bottom: 2*$doubleSpace;
  text-transform: uppercase;
}
