@import '../index.scss';

.LanguageSwitch__wrapper {
    display: flex;
    align-items: center;
    height: 16px;
}

.LanguageSwitch {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: none;
    background-color: $color-blanc;
    font-family: $font-family-roboto-regular;
    font-size: 12px;
    line-height: 1.33;
    color: $color-noir;
    text-transform: uppercase;
    margin-left: 24px;
    cursor: pointer;
    display: flex;
    align-items: center;

    background-image:url(./../icons/baseline-expand_down-24px.svg);
    background-position: right;
    background-repeat: no-repeat;
    width: 40px;

    &:active, &:focus {
        outline: none;
    }
}

.LanguageSwitch__icon {
    margin-left: 5px;
    margin-bottom: 3px;
}