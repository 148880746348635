@import "../../index.scss";

.IpuroLink {
  display: inline-flex;
  position: relative;
  align-items: center;
  font-family: $font-family-roboto-medium;
  font-size: 16px;
  line-height: 1.5;
  color: $color-noir-alpha;

  &:hover {
    transition: color $animationSpeedDefault ease-in-out;
    color: $color-noir;

    .IpuroLink__icon {
      transition: fill $animationSpeedDefault ease-in-out;
      fill: $color-noir;
    }
  }

  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    div[style] {
      width: 100%;
    }
  }
}

.IpuroLink___backlink {
  color: $color-noir;

  .IpuroLink__icon {
    fill: $color-noir;
  }

  &:hover {
    color: $color-noir-alpha;

    .IpuroLink__icon {
      fill: $color-noir-alpha;
    }
  }
}

.IpuroLink___secondary {
  font-family: $font-family-roboto-regular;

  &:after {
    content: "";
    width: 100%;
    height: 1px;
    background-color: $color-noir-alpha;
    left: 0;
    position: absolute;
    display: block;
    bottom: 5px;
  }

  &:hover:after {
    transition: background-color $animationSpeedDefault ease-in-out;
    background-color: $color-noir;
  }
}

.IpuroLink__icon {
  fill: $color-noir-alpha;
  margin-bottom: 1px;
}
