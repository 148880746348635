@import "../sass/variables";
@import "../sass/typography";
@import "../sass/mixins";

.copy {
  font-size: 16px;
  text-align: left;
  margin-top: 4 * $doubleSpace;
}

.button {
  margin-top: 3 * $doubleSpace;
}

.previewImage {
  width: 100%;
  padding: 0 1px; // needs to be the same as .image-gallery-slide
}

.moodImage {
  width: 100%;
}

.column {
  position: relative;
}

.pricesWrapper {
  display: flex;
  align-items: flex-end;
}
.price {
  font-family: $font-family-roboto-regular;
  font-size: 14x;
  line-height: 20px;
  margin-top: $doubleSpace;
  margin-bottom: 3 * $doubleSpace;

  @include smallScreen {
    font-size: 20px;
    line-height: 1.2;
    margin-top: 2 * $doubleSpace;
  }
}

.basicPrice {
  @extend .price;
  color: $color-bois;
  font-size: 10px;
  margin-left: 10px;
  @include smallScreen {
    font-size: 14px;
    line-height: 20px;
  }
}

.accordeonWrapper {
  position: absolute;
  padding-right: 0.75rem;
  z-index: 1;
}

.accordeon {
  background: $color-blanc;
  margin-top: 2 * $doubleSpace;
}

.section {
  margin-top: 18 * $doubleSpace;
}

.modalText {
  font-family: $font-family-gotham-book;
  font-size: 20px;
}

.logos {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 8 * $space;
}

.logo {
  height: 70px;
}

.logo--depot {
  height: 20px;
}

.logo {
  margin: 0 10 * $doubleSpace;
}

.headlineRelated {
  @include smallScreen {
    padding-bottom: 10 * $space;
  }
}
