@import "../../index.scss";

.wrapper {
  box-sizing: border-box;
  display: flex;
  justify-content: flex-end;
  overflow: hidden;
  position: relative;
  padding: 145% $space 0;

  img {
    position: absolute;
    top: 45px;
    left: 0;

    @include mediumScreen {
      left: 20%;
    }
  }

  @include smallScreen {
    // padding: 100% 4 * $doubleSpace 0;
    padding-top: 130%;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
  }

  @include mediumScreen {
    // padding: 100% 4 * $doubleSpace 0;
    padding-top: 115%;
    padding-bottom: 0;
    padding-left: 4 * $doubleSpace;
    padding-right: 0;
  }

  &.small {
    flex-direction: column;
    padding: 0 $space;
    justify-content: flex-start;

    @include smallScreen {
      padding: 0;
    }

    .badge {
      top: 0;
      position: absolute;
      z-index: 1;
      margin-left: -$space;
      margin-top: $space;

      @include smallScreen {
        position: static;
        height: 32px;
        margin-right: 0;
        justify-content: flex-end;
        display: flex;
      }
    }

    .badgeText {
      position: static;
      padding: 0 $space;

      margin-bottom: $doubleSpace;

      margin: 0;

      @include smallScreen {
        height: 24px;
        margin-bottom: $doubleSpace;
        font-size: 16px;
      }
    }

    .product {
      display: flex;
      flex-direction: column;
    }
  }
}

.product {
  cursor: pointer;
  width: 100%;

  @include mediumScreen {
    &:hover {
      .text {
        transform: translateY(-34px);
      }
    }

    .text {
      transform: translateY(0);
    }
  }
}

.badgeText {
  top: 0;
  background: $color-noir;
  font-size: 12px;
  font-family: $font-family-gotham-book;
  text-transform: uppercase;
  color: $color-blanc;
  display: inline-block;
  position: absolute;
  margin-left: -$space;
  margin-top: $space;
  padding: 0 $space;

  @include smallScreen {
    left: 0;
    height: 40px;
    font-size: 28px;
    padding: 0 2 * $doubleSpace;
    margin-right: 2 * $doubleSpace;
  }
}

.image {
  display: block;
  width: 100%;

  @include mediumScreen {
    max-width: 310px;
  }
}

.text {
  font-family: $font-family-roboto-regular;
  background: $color-blanc;
  text-align: right;
  color: $color-noir;
  padding: 2 * $doubleSpace 0 $doubleSpace 0;
  padding-right: 2 * $doubleSpace;
  transition: transform $animationSpeedDefault ease-in-out;
  position: relative;
  display: flex;
  flex-direction: column;
  align-content: space-between;
  min-height: 114px;

  @include mediumScreen {
    min-height: 0;
    display: block;
  }
}

.headline {
  font-family: $font-family-roboto-medium;
  font-size: 14px;
  line-height: 1.2;

  @include smallScreen {
    font-size: 20px;
  }
}

.type {
  font-size: 14px;

  @include smallScreen {
    font-size: 16px;
  }
}

.price {
  font-size: 14px;
  width: 100%;
  background: $color-blanc;
  padding-top: 2 * $doubleSpace;
  padding-right: 2 * $doubleSpace;

  @include mediumScreen {
    font-size: 16px;
    position: absolute;
    margin-top: 0;
    padding-top: 2 * $doubleSpace;
  }
}

.productLine {
  font-size: 12px;
  margin-top: $doubleSpace;
}
