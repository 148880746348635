@import '../../index.scss';

.TopBarItem {
    height: 16px;
    font-family: $font-family-roboto-regular;
    font-size: 12px;
    line-height: 1.33;
    color: $color-noir;
    text-transform: uppercase;
    margin-left: 24px;
    cursor: pointer;
    display: flex;
    align-items: center;
    
    a {
        color: $color-noir;
        &:hover {
            color: $color-noir;
        }
    }
}

.TopBarItem__icon {
    margin-left: 5px;
}
