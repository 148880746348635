@import '../sass/variables';
@import '../sass/mixins';

@keyframes slideIn {
  from {
    transform: translateX(-100%);
  }

  to {
    transform: translateX(0%);
  }
}

$MAP_HEIGHT: 822px;
$MAP_HEIGHT_SMALL: 400px;

.container {
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  width: calc(100% + 2 * #{$outerPadding});
  margin-left: -$outerPadding;

  @include extraSmallScreen {
    height: $MAP_HEIGHT;
    flex-direction: row;
    width: 100%;
    margin-left: 0;
  }
}

.results {
  overflow: auto;
  background: $color-blanc;

  @include extraSmallScreen {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    transform: translateX(-100%);
    transition: all $animationSpeedDefault ease-in-out;
    z-index: 1;

    &.isActive {
      animation-name: slideIn;
      animation-duration: $animationSpeedSlow;
      animation-fill-mode: forwards;
    }
  }

}

.result {
  border-bottom: 1px solid $color-noir;
  padding: 3*$space;
  font-size: 16px;
  line-height: 24px;
  transition: background $animationSpeedDefault ease-in-out;
  display: block;

  &:hover {
    background: $color-grey-light;
  }
}

.tags {
  margin: $doubleSpace 0;
}

.tag {
  &:last-of-type {
    &::after {
      content: '';
    }
  }

  &::after {
    content: ' - ';
  }
}

.map {
  width: 100%;
  height: $MAP_HEIGHT_SMALL;
  position: relative !important;

  @include extraSmallScreen {
    height: $MAP_HEIGHT;
    position: absolute !important;
  }
}

.mapWrapper {
  transition: all $animationSpeedDefault ease-in-out;
  height: $MAP_HEIGHT_SMALL;

}

.link {
  color: $color-noir-alpha;
  text-decoration: underline;
}

.itemHeadline {
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  font-weight: bold;
}
