@import "../../index.scss";

.SocialMediaIcons {
  display: flex;
  flex-direction: row;
  margin-top: 3 * $space;
  padding: $doubleSpace;

  @include mediumScreen {
    padding: $doubleSpace 0;
  }
}

.SocialMediaIcons__link {
  height: 40px;
  width: 40px;

  &:hover {
    .SocialMediaIcons__icon {
      transition: fill $animationSpeedDefault ease-in-out;
      fill: $color-noir-alpha;
    }
  }
}

.SocialMediaIcons__icon {
  fill: $color-noir;
}
