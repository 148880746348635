@import '../../index.scss';

.AccordeonPanel {
  margin-bottom: $doubleSpace;
  overflow:hidden;
  background: $color-blanc;

  &__title {
    border: 1px solid $color-grey;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: $doubleSpace 2*$doubleSpace;
  }
  &__content {
    max-height: 0;
    transition: max-height $animationSpeedSlow ease-in-out;
    overflow:hidden;

    &.open {
      max-height: 200vh; /* TODO: max height needs to be defined */
    }
  }

  &__copy {
    text-align: left;
    padding: $doubleSpace;
    border: 1px solid $color-grey;
    border-top: 0;
  }
}
