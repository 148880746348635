/* breakpoints */
@mixin extraSmallScreen() {
  @media only screen and (min-width: $extra-small-screen-min-width) {
    @content;
  }
}

@mixin smallScreen() {
  @media only screen and (min-width: $small-screen-min-width) {
    @content;
  }
}

@mixin mediumScreen() {
  @media only screen and (min-width: $medium-screen-min-width) {
    @content;
  }
}

@mixin largeScreen() {
  @media only screen and (min-width: $large-screen-min-width) {
    @content;
  }
}

@mixin extraLargeScreen() {
  @media only screen and (min-width: $extra-large-screen-min-width) {
    @content;
  }
}

@mixin fluentScroll($overflowX: scroll, $overflowY: hidden) {
  /* one of them has to be scroll, not auto */
  overflow-x: $overflowX;
  overflow-y: $overflowY;
  -webkit-overflow-scrolling: touch;
}

/* Headlines */

@mixin h1 {
  font-size: 56px;
  line-height: 64px;
}

@mixin h2 {
  font-size: 48px;
  line-height: 56px;
}

@mixin h3 {
  font-size: 40px;
  line-height: 48px;
}

@mixin h4 {
  font-size: 32px;
  line-height: 40px;
}

@mixin h5 {
  font-size: 28px;
  line-height: 40px;
}

@mixin h6 {
  font-size: 20px;
  line-height: 28px;
}

@mixin h7 {
  font-size: 20px;
  line-height: 28px;
}

/* General */

@mixin wordbreak {
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
}
