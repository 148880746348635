@import "../../index.scss";

.CompositionTeaserMobile {
  margin-bottom: 4 * $space;
  margin-top: 12 * $space;
  padding: 0.75em;
}

.CompositionTeaserMobile__columns___reversed {
  flex-direction: row-reverse;
  justify-content: flex-end;
}

.CompositionTeaserMobile__mixedColumn {
  display: flex;
  flex-direction: column;

  img {
    padding-bottom: 2 * $space;
  }
}

.CompositionTeaserMobile__mixedColumn___withQuote {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 9 * $space 0.75em 0;

  img {
    width: 50%;
    padding-bottom: 2 * $space;
  }
}

.CompositionTeaserMobile__headlines {
  display: flex;
  flex-direction: column;
  flex: 1;

  h1 {
    font-size: 32px;
    padding-bottom: $doubleSpace;
    line-height: 1.25;
  }

  &__subheadline {
    text-align: left;
    font-family: $font-family-roboto-medium;
    font-weight: 500;
    color: $color-noir;
    margin-bottom: 8px;
    font-size: 14px;
    line-height: 20px;

    @include smallScreen {
      font-size: 24px;
      line-height: 1.33;
    }
  }
}

.CompositionTeaserMobile__optionalColumn {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  p,
  blockquote {
    text-align: right;
  }

  a,
  p {
    align-self: flex-end;
  }

  div {
    margin-bottom: 0;
    padding: 0;
  }
}
