@import '../../sass/variables';
@import '../../sass/mixins';
@import '../../sass/typography';


.Logo {
    height: 35px;
    margin-top: $doubleSpace;

    @include smallScreen {
      height: 80px;
      width: 104px;
      margin-top: 0;
    }
}
