@import "../../sass/variables";
@import "../../sass/mixins";

.wrapper {
  display: flex;
  position: relative;
  height: $mainNavigationHeightMobile;
  justify-content: center;
  align-items: center;
  background: $color-blanc;
  transition: box-shadow $animationSpeedDefault ease-in-out;

  @include smallScreen {
    display: block;
    align-items: center;
    height: $mainNavigationHeight;
    margin-top: -6px;
  }

  @include mediumScreen {
    margin-top: unset;
  }

  &.isFixed {
    box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.15);
  }
}
