/* colors */
$color-blanc: #ffffff;
$color-grey: #d7d7d7;
$color-grey-light: #eeeeee;
$color-cuir-noble: #a0a5a0;
$color-epice: #55504b;
$color-noir: #000;
$color-noir-alpha: rgba(0, 0, 0, 0.5);
$color-santal-blanc: #dcd7c8;
$color-cachemire: #a09691;
$color-bois: #645a4b;
$color-purete: #af9182;
$color-cuir: #965f50;
$color-orchidee: #ebb9c8;
$color-jardin: #dc4b5f;
$color-cinabre: #e64641;
$color-bergamote: #c3d782;
$color-balance: #7da0c3;

/* spacer */
$space: 4px;
$doubleSpace: 2 * $space;

/* outer padding */
$outerPadding: 0.75em;
$outerPaddingDesktop: 5vw;

/* break points */
$extra-extra-small-screen-max-width: 768px;
$extra-small-screen-max-width: 980px;
$small-screen-max-width: 1280px;
$medium-screen-max-width: 1440px;
$large-screen-max-width: 1680px;

$extra-small-screen-min-width: $extra-extra-small-screen-max-width + 1px;
$small-screen-min-width: $extra-small-screen-max-width + 1px;
$medium-screen-min-width: $small-screen-max-width + 1px;
$large-screen-min-width: $medium-screen-max-width + 1px;
$extra-large-screen-min-width: $large-screen-max-width + 1px;

/* animation speed */
$animationSpeedSlow: 400ms;
$animationSpeedDefault: 200ms;
$animationSpeedFast: 100ms;

/* head */
$topbarHeight: 48px;
$mainNavigationHeight: 80px;
$mainNavigationHeightMobile: 64px;
$headHeight: $topbarHeight + $mainNavigationHeight;
$headHeightMobile: $mainNavigationHeightMobile;

:export {
  animationSpeedSlow: $animationSpeedSlow;
  animationSpeedDefault: $animationSpeedDefault;
  animationSpeedFast: $animationSpeedFast;
}
