@import '../../sass/variables';
@import '../../sass/typography.scss';
@import '../../sass/mixins';

.wrapper {
  font-family: $font-family-gotham-book;
  text-transform: uppercase;
  text-align: left;
}

.black {
  color: $color-noir;
}

.grey {
  color: $color-cuir-noble;
}

.h1 {
  @include h7;
  margin-bottom: $doubleSpace*2;
  // background: lightblue;

  @include extraSmallScreen {
    @include h4;
    margin-bottom: $doubleSpace*3;
    // background: blue;
  }

  @include smallScreen {
    @include h2;
    margin-bottom: $doubleSpace*3;
    // background: blueviolet;
  }

  @include mediumScreen {
    @include h1;
    margin-bottom: $doubleSpace*3;
    // background: violet;
  }
}

.h2 {
  @include h7;
  // background: yellow;

  @include extraSmallScreen {
    @include h6;
    // background: orange;
  }

  @include smallScreen {
    @include h4;
    // background: orangered;
  }

  @include mediumScreen {
    @include h2;
    // background: orange;
  }
}

.h3 {
  @include h7;
  // background: lightcoral;

  @include smallScreen {
    @include h4;
   // background: rgb(197, 106, 106);
  }

  @include mediumScreen {
    @include h3;
    // background: rgb(151, 80, 80);
  }
}

.h4 {
  @include h7;

  @include smallScreen {
    @include h4;
  }
}

.h5 {
  @include h5;
}

.h6 {
  @include h6;
}
