@import "../index.scss";

.headline___main {
  margin-top: 15 * $space;

  @include smallScreen {
    margin-top: 0;
  }
}

.headline {
  padding-top: 19 * $space;
  padding-bottom: 11 * $space;
}
