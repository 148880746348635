@import '../../index.scss';

.FaqList {
    text-align: left;
    margin-top: 11*$doubleSpace;
    margin-bottom: 12*$doubleSpace;
    
    .FaqList__listItem {
        margin-bottom: 9*$doubleSpace;
    }
}


.FaqList__question {
    font-family: $font-family-gotham-book;
    font-size: 40px;
    line-height: 1.2;
    color: $color-noir;
    text-transform: uppercase;
}

.FaqList__answer {
    font-family: $font-family-roboto-regular;
    font-size: 16px;
    line-height: 1.5;
    color: $color-noir;
    margin: 5*$space 0 6*$space 0;
}