@import '../sass/variables';
@import '../sass/mixins';

.wrapper {
  text-align: left;
}

.formWrapper {
  margin-top: 10*$space;

  @include smallScreen {
    margin-top: 21*$space;
  }
}

.label {
  position: absolute;
  font-size: 12px;
  margin-top: -3*$doubleSpace;
}

.autocomplete-root {

}

.autocomplete-dropdown-container {
  position: absolute;
  z-index: 3;
  background: $color-blanc;
}

.autocomplete-dropdown-list {
  li {
    padding: $doubleSpace 2*$doubleSpace;
    color: $color-noir;

    &:hover {
      cursor: pointer;
    }
  }
}

.storefinder-suggestion {

}

.storefinder-suggestion-active {
  background: $color-grey-light;
}
