@import '../../index.scss';

.BigQuote {
    display: flex;
    flex-direction: column;
    margin: 12*$doubleSpace 0;
}

.BigQuote__headline {
    font-family: $font-family-roboto-medium;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: $color-noir;
    margin-top: 2*$doubleSpace;
    text-align: center;

    @include smallScreen {
      font-size: 24px;
      line-height: 1.33;
  }

}

.BigQuote__quote {
    font-family: $font-family-gotham-book;
    font-size: 20px;
    line-height: 1.4;
    color: $color-noir;
    text-transform: uppercase;
    text-align: center;

    @include smallScreen {
        font-size: 40px;
        line-height: 1.2;
    }
}

